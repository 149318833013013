import React from "react"

import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

const Portfolio = () => (
  <Layout>
    <SEO title="Stack Bit Labs portfolio" />
  </Layout>
)

export default Portfolio
